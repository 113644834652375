import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return (
        <Navbar className="bg-body-tertiary navbar">
            <Container>
            <Nav className="me-right mx-auto">
                <Nav.Link as={NavLink} to="/">HOME</Nav.Link>
                <Nav.Link as={NavLink} to="/apartamenty">APARTAMENTY</Nav.Link>
                <Nav.Link as={NavLink} to="/atrakcje">ATRAKCJE</Nav.Link>
                <Nav.Link as={NavLink} to="/galeria">GALERIA</Nav.Link>
                <Nav.Link as={NavLink} to="/cennik">CENNIK</Nav.Link>
                <Nav.Link as={NavLink} to="/kontakt">KONTAKT</Nav.Link>
            </Nav>
            </Container>
        </Navbar>
    )
}

export default NavBar;