import { Link } from 'react-router-dom';
import { Container } from "react-bootstrap";

const Apartaments = () => {
    return (
        <Container>
            <h1 className="headerTop">Apartamenty</h1>
            <h4 style={{fontSize: "20px", paddingBottom: "30px", marginLeft: '12px'}}>
            Z przyjemnością informujemy, że w naszej ofercie znajdują się dwa wyjątkowe apartamenty, każdy z nich zaprojektowany z myślą o 
            zapewnieniu maksymalnego komfortu i satysfakcji naszych gości. Nasze apartamenty to idealne rozwiązanie zarówno dla podróżujących 
            służbowo, jak i dla tych, którzy pragną odpocząć i zrelaksować się w przestronnym, elegancko urządzonym wnętrzu. Zapraszamy do 
            zapoznania się z naszą ofertą, w której każdy znajdzie coś dla siebie, niezależnie od potrzeb i preferencji.
            <p></p>
            Apartamenty są przeznaczone dla 2-4 osób. Każdy apartament udostępnia bezprzewodowe Wi-Fi oraz telewizję naziemną, a także bieliznę pościelową oraz ręczniki. Dodatkowym atutem jest bezpłatny parking dla gości - prywatne miejsca parkingowe znajdują się na terenie osiedla. 
            </h4>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0'}}>
                <Link to="/apartament1" style={{ flex: 1, margin: '0 10px' }}>
                    <div style={{ position: 'relative' }}>
                        <img src='https://apartamentyportabaltica.pl/images/Industrial-Loft.jpg' alt="A2" style={{ width: '100%', height: 'auto', transition: 'transform 0.3s ease', filter: 'brightness(0.5)' }} />
                        <div className="apText">
                            Apartament "Industrial Loft"
                        </div>
                    </div>
                </Link>
                <Link to="/apartament2" style={{ flex: 1, margin: '0 10px', position: 'relative', display: 'block' }}>
                    <div style={{ position: 'relative' }}>
                        <img src='https://apartamentyportabaltica.pl/images/Floral-Chic.jpg' alt="A2" style={{ width: '100%', height: 'auto', transition: 'transform 0.3s ease', filter: 'brightness(0.5)' }} />
                        <div className="apText">
                            Apartament "Floral Chic"
                        </div>
                    </div>
                </Link>
            </div>
            <h1 className="headerTop">Udogodnienia</h1>
            <div class="row gutters-sm">
                <div class="col-sm-6 col-xl-3 mb-3">
                    <div class="card-ap card">
                        <div class="card-header border-bottom flex-column align-items-start p-3">
                            <h4 class="text-secondary font-weight-light mb-2">Udogodnienia dla dzieci</h4>
                        </div>
                        <div class="card-body">
                        <ul class="list-unstyled font-size-sm mb-0">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Łóżeczko dla dziecka</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Pościel dla dzieci</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Plac zabaw</strong></li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3 mb-3">
                    <div class="card card-ap">
                        <div class="card-header border-bottom flex-column align-items-start p-3">
                            <h4 class="text-secondary font-weight-light mb-2">Parking</h4>
                        </div>
                        <div class="card-body">
                        <ul class="list-unstyled font-size-sm mb-0">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Obiekt oferuje 2 bezpłatne miejsca parkingowe dla gości!</strong></li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3 mb-3">
                    <div class="card card-ap">
                        <div class="card-header border-bottom flex-column align-items-start p-3">
                            <h4 class="text-secondary font-weight-light mb-2">Wyposażenie w łazienkach</h4>
                        </div>
                        <div class="card-body">
                        <ul class="list-unstyled font-size-sm mb-0">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Ręczniki</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Suszarka do włosów</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Prysznic</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Dostęp do pralki</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Suszarka na ubrania</strong></li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3 mb-3">
                    <div class="card card-ap">
                        <div class="card-header border-bottom flex-column align-items-start p-3">
                            <h4 class="text-secondary font-weight-light mb-2">W apartamentach</h4>
                        </div>
                        <div class="card-body">
                        <ul class="list-unstyled font-size-sm mb-0">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Telewizor</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Szybki internet światłowodowy</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Lodówka</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Czajnik elektryczny</strong></li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg>&nbsp;<strong>Aneks kuchenny</strong></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className="headerTop">Ogólne informacje</h1>
            <table className="table">
            <tbody>
                <tr>
                <th scope="row">Zameldowanie</th>
                <td>od 15:30 do 22:00</td>
                </tr>
                <tr>
                <th scope="row">Wymeldowanie</th>
                <td>do godziny 11:00</td>
                </tr>
                <tr>
                <th scope="row">Dostępne rodzaje płatności</th>
                <td>gotówka, płatność przelewem</td>
                </tr>
                <tr>
                <th scope="row">Zaliczka</th>
                <td>Obiekt pobiera zaliczki w wysokości 30% kwoty za pobyt. Zaliczkę należy wpłacić w ciągu 3 dni od dokonania rezerwacji. </td>
                </tr>
                <tr>
                <th scope="row">Rezygnacja</th>
                <td>obiekt pobiera opłaty za rezygnację z rezerwacji (po wpłacie zadatku, brak możliwości anulacji rezerwacji)</td>
                </tr>
            </tbody>
            </table>
            <h1 style={{marginBottom: "10px"}}></h1>
        </Container>
    )
}

export default Apartaments;