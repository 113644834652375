import { Container } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: "https://i.ibb.co/zfTW83X/IMG-20210609-171820-min.jpg",
    thumbnail: "https://i.ibb.co/zfTW83X/IMG-20210609-171820-min.jpg",
  },
  {
    original: "https://i.ibb.co/0nTZgzN/IMG-20210519-113805-min.jpg",
    thumbnail: "https://i.ibb.co/0nTZgzN/IMG-20210519-113805-min.jpg",
  },
  {
    original: "https://i.ibb.co/CH6N5VJ/IMG-20210517-191319-min.jpg",
    thumbnail: "https://i.ibb.co/CH6N5VJ/IMG-20210517-191319-min.jpg",
  },
  {
    original: "https://i.ibb.co/Kb0NjKZ/IMG-20210517-191356-min.jpg",
    thumbnail: "https://i.ibb.co/Kb0NjKZ/IMG-20210517-191356-min.jpg",
  },
  {
    original: "https://i.ibb.co/Nm97tVG/IMG-20210517-191125.jpg",
    thumbnail: "https://i.ibb.co/Nm97tVG/IMG-20210517-191125.jpg",
  },
  {
    original: "https://i.ibb.co/yB49fxH/IMG-20210517-191556.jpg",
    thumbnail: "https://i.ibb.co/yB49fxH/IMG-20210517-191556.jpg",
  },
  {
    original: "https://i.ibb.co/JC7JMTZ/IMG-20210519-113734-min.jpg",
    thumbnail: "https://i.ibb.co/JC7JMTZ/IMG-20210519-113734-min.jpg",
  },
  {
    original: "https://i.ibb.co/KXJtZ3z/IMG-20210517-191250-min.jpg",
    thumbnail: "https://i.ibb.co/KXJtZ3z/IMG-20210517-191250-min.jpg",
  },
  {
    original: "https://i.ibb.co/N7mQgDR/IMG-20230108-115104.jpg",
    thumbnail: "https://i.ibb.co/N7mQgDR/IMG-20230108-115104.jpg",
  },
  {
    original: "https://i.ibb.co/X3sjtHJ/IMG-20210519-114851-min.jpg",
    thumbnail: "https://i.ibb.co/X3sjtHJ/IMG-20210519-114851-min.jpg",
  },
  {
    original: "https://i.ibb.co/5KY5nR9/IMG-20210517-193107.jpg",
    thumbnail: "https://i.ibb.co/5KY5nR9/IMG-20210517-193107.jpg",
  },
  {
    original: "https://i.ibb.co/CvCyVG7/IMG-20210519-114816-min.jpg",
    thumbnail: "https://i.ibb.co/CvCyVG7/IMG-20210519-114816-min.jpg",
  },
  {
    original: "https://i.ibb.co/Tt0HjgZ/IMG-20210517-193638.jpg",
    thumbnail: "https://i.ibb.co/Tt0HjgZ/IMG-20210517-193638.jpg",
  },
  {
    original: "https://i.ibb.co/LQB0dHn/IMG-20210517-193425.jpg",
    thumbnail: "https://i.ibb.co/LQB0dHn/IMG-20210517-193425.jpg",
  },
  {
    original: "https://i.ibb.co/WF4Q8j2/IMG-20210519-114750-min.jpg",
    thumbnail: "https://i.ibb.co/WF4Q8j2/IMG-20210519-114750-min.jpg",
  },
  {
    original: "https://i.ibb.co/S3Rhy2K/IMG-20210519-114622-min.jpg",
    thumbnail: "https://i.ibb.co/S3Rhy2K/IMG-20210519-114622-min.jpg",
  },
  {
    original: "https://i.ibb.co/d7vm093/IMG-20210517-192724-min.jpg",
    thumbnail: "https://i.ibb.co/d7vm093/IMG-20210517-192724-min.jpg",
  },
  {
    original: "https://i.ibb.co/sbH5QHv/IMG-20210517-183800.jpg",
    thumbnail: "https://i.ibb.co/sbH5QHv/IMG-20210517-183800.jpg",
  },
  {
    original: "https://i.ibb.co/51rNkxL/Droga.png",
    thumbnail: "https://i.ibb.co/51rNkxL/Droga.png",
  },
];

const Gallery = () => {
    return (
        <Container>
            <h1 className="headerTop">Galeria</h1>
            <h4 style={{fontSize: "20px", paddingBottom: "30px", marginLeft: '12px', textAlign: "center"}}>
            Odkryj wyjątkowe apartamenty, które oferujemy. Zapraszamy do zapoznania się z naszą galerią.
            </h4>
            <ImageGallery items={images} showFullscreenButton={true} showPlayButton={false} />
            <h1 style={{marginBottom: "10px"}}></h1>
        </Container>
    )
}

export default Gallery;