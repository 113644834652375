import { Container } from "react-bootstrap";

const Pricing = () => {
    return (
        <Container>
            <h1 className="headerTop">Cennik</h1>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">Data</th>
                <th scope="col">Industrial Loft (apartament max. 4 osoby)</th>
                <th scope="col">Floral Chic (apartament max. 4 osoby)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <th scope="row">*31.07-14.08.2024r.</th>
                <td>450,00 zł/doba</td>
                <td>470,00 zł/doba</td>
                </tr>
                <tr>
                <th scope="row">*18.08-25.08.2024r.</th>
                <td>450,00 zł/doba</td>
                <td>470,00 zł/doba</td>
                </tr>
                <tr>
                <th scope="row">*25.08-31.08.2024r.</th>
                <td>400,00 zł/doba</td>
                <td>430,00 zł/doba</td>
                </tr>
                <tr>
                <th scope="row">**01.09-30.09.2024r.</th>
                <td>330,00 zł/doba</td>
                <td>350,00 zł/doba</td>
                </tr>
                <tr>
                <th scope="row">30.09-31.10.2024r.</th>
                <td>300,00 zł/doba</td>
                <td>320,00 zł/doba</td>
                </tr>
                <tr>
                <th scope="row">31.10-20.12.2024r.</th>
                <td>250,00 zł/doba</td>
                <td>280,00 zł/doba</td>
                </tr>
            </tbody>
            </table>

            <p>* w sezonie letnim - wynajem na min. 5 noclegów</p>
            <p>** wynajem na min. 3 noclegi</p>
            <p>pozostałe miesiące - wynajem na min. 2 noclegi</p>
            <p>ceny negocjowane przy pobytach powyżej 7 noclegów</p>
            <p></p>
            <p>opłata klimatyczna - 6.20 zł/doba/osoba</p>
            <p>prywatne miejsce parkingowe - b/opłat</p>
            <h1 style={{marginBottom: "70px"}}></h1>
        </Container>
    )
}

export default Pricing;