import React, { useState, useEffect } from 'react';

function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = [
    { url: 'https://apartamentyportabaltica.pl/images/karuzela/osiedle.jpg', text: 'Apartamenty Porta Baltica - Odkryj nasze luksusowe apartamenty wypoczynkowe zlokalizowane nad samym morzem. Idealne miejsce na wypoczynek i ucieczkę od codzienności!', className: 'carouseltext' },
    { url: 'https://apartamentyportabaltica.pl/images/karuzela/promenada.jpg', text: 'Apartamenty Porta Baltica - Odkryj nasze luksusowe apartamenty wypoczynkowe zlokalizowane nad samym morzem. Idealne miejsce na wypoczynek i ucieczkę od codzienności!', className: 'carouseltext' },
    { url: 'https://apartamentyportabaltica.pl/images/karuzela/floral.jpg', text: 'Apartamenty Porta Baltica - Odkryj nasze luksusowe apartamenty wypoczynkowe zlokalizowane nad samym morzem. Idealne miejsce na wypoczynek i ucieczkę od codzienności!', className: 'carouseltext' },
    { url: 'https://apartamentyportabaltica.pl/images/karuzela/industrial.jpg', text: 'Apartamenty Porta Baltica - Odkryj nasze luksusowe apartamenty wypoczynkowe zlokalizowane nad samym morzem. Idealne miejsce na wypoczynek i ucieczkę od codzienności!', className: 'carouseltext' }
  ];

  const goNext = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(goNext, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="carousel">
      {slides.map((slide, index) => (
        <div className={index === activeIndex ? 'slide active' : 'slide'} key={slide.url}>
          <img src={slide.url} alt={`Slide ${index}`} />
          <div className="text" style={{zindex: "1"}}>{slide.text}</div>
        </div>
      ))}
    </div>
  );
}

export default Carousel;