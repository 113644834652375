import { Container } from "react-bootstrap";

const Attractions = () => {
    return (
        <Container>
        <div className="atrakcje">
            <h1 className="headerTop">Atrakcje w Kołobrzegu</h1>
            <h4 style={{fontSize: "20px", paddingBottom: "30px"}}>
            Zapraszamy do odkrycia uroków Kołobrzegu, malowniczego miasta położonego nad brzegiem Bałtyku, 
            które jest idealnym miejscem na wypoczynek o każdej porze roku. Miłośnicy przyrody będą zachwyceni spacerami po rozległym Parku 
            Nadmorskim i relaksującymi kąpielami w solankowych basenach. Kołobrzeg to również doskonałe miejsce dla osób szukających odpoczynku na plaży, 
            oferując szerokie, piaszczyste plaże idealne do słonecznych kąpieli i spacerów o zachodzie słońca. 
            Nie przegap okazji, by cieszyć się świeżymi, lokalnymi przysmakami w licznych restauracjach i kawiarniach. Kołobrzeg czeka, by Cię oczarować!
            </h4>
            <div className="atrakcje-img">
                <div style={{ flex: 1, paddingRight: '20px'}}>
                <img src="https://apartamentyportabaltica.pl/images/atrakcje/molo.jpg" alt="Molo" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ flex: 1, marginBottom: '30px', marginTop: '30px' }}>
                <p>
                    Molo w Kołobrzegu, rozciągające się daleko na wody Bałtyku, jest jednym z najbardziej malowniczych miejsc tego nadmorskiego kurortu. Spacerując po drewnianym pomostie, możesz 
                    delektować się świeżym, jodowym powietrzem, podziwiając przy tym widok rozbijających się o brzeg fal. To idealne miejsce na romantyczny spacer o zachodzie słońca. Molo często 
                    staje się areną różnorodnych wydarzeń kulturalnych i muzycznych, co dodatkowo wzbogaca ofertę turystyczną miasta. Odwiedzając Molo w Kołobrzegu, zyskasz nie tylko wspaniałe 
                    wspomnienia, ale i okazję do podziwiania panoramy wybrzeża z unikalnej perspektywy. Nie przegap tej wyjątkowej atrakcji, która z pewnością stanie się jednym z najjaśniejszych 
                    punktów Twojej wizyty w Kołobrzegu!</p>
                </div>
            </div>

            <div className="atrakcje-img">
                <div style={{ flex: 1, paddingRight: '20px' }}>
                <img src="https://apartamentyportabaltica.pl/images/atrakcje/latarnia.jpg" alt="Latarnia" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ flex: 1, marginBottom: '30px', marginTop: '30px' }}>
                <p>
                    Latarnia morska w Kołobrzegu, majestatycznie wznosząca się nad brzegiem Bałtyku, jest jednym z najbardziej rozpoznawalnych i fascynujących zabytków tego nadmorskiego kurortu. 
                    Wybudowana w 1945 roku na ruinach starej latarni, obecnie stanowi nie tylko kluczowy punkt nawigacyjny, ale także wyjątkową atrakcję turystyczną. Wejście na jej szczyt, gdzie 
                    rozpościera się spektakularny, panoramiczny widok na morze i okolicę, dostarcza niezapomnianych wrażeń. To idealne miejsce dla tych, którzy pragną poczuć magię morskich podróży 
                    oraz zrobić pamiątkowe zdjęcia z jednego z najpiękniejszych punktów w mieście. </p>
                </div>
            </div>

            <div className="atrakcje-img">
                <div style={{ flex: 1, paddingRight: '20px' }}>
                <img src="https://apartamentyportabaltica.pl/images/atrakcje/muzeum.jpg" alt="Muzeum" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ flex: 1, marginBottom: '30px', marginTop: '30px' }}>
                <p>
                    Muzeum Oręża Polskiego w Kołobrzegu to fascynujące miejsce, które przenosi odwiedzających w czasie, prezentując bogatą historię militarną Polski. Zlokalizowane w historycznych murach 
                    twierdzy kołobrzeskiej, muzeum oferuje ekspozycje, które obejmują zbiory broni, mundurów oraz różnorodnych artefaktów wojskowych, od średniowiecza po czasy współczesne. Odwiedzając 
                    to miejsce, można zobaczyć między innymi czołgi, samoloty, a także artylerię, które są wystawione na otwartym terenie, tworząc imponującą ekspozycję na świeżym powietrzu. Muzeum Oręża 
                    Polskiego to nie tylko lekcja historii, ale również okazja, aby zrozumieć wpływ wydarzeń wojskowych na kształtowanie się polskiej tożsamości narodowej. Niezależnie od tego, czy jesteś 
                    miłośnikiem historii, czy po prostu szukasz interesującej atrakcji, Muzeum Oręża Polskiego w Kołobrzegu dostarczy ci niezapomnianych wrażeń.</p>
                </div>
            </div>

            <div className="atrakcje-img">
                <div style={{ flex: 1, paddingRight: '20px' }}>
                <img src="https://apartamentyportabaltica.pl/images/atrakcje/trampolina.jpg" alt="Trampolin" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ flex: 1, marginBottom: '30px', marginTop: '30px' }}>
                <p>
                    Park trampolin "Skakun" w Kołobrzegu to popularne miejsce rekreacji, które oferuje mnóstwo atrakcji dla miłośników aktywnego spędzania czasu. Znajdują się tam liczne trampoliny, strefy do skoków akrobatycznych i tory przeszkód, które dostarczają niezapomnianych wrażeń zarówno dzieciom, jak i dorosłym. To idealne miejsce na zabawę, rozwijanie umiejętności sportowych i integrację z przyjaciółmi. </p>
                </div>
            </div>

            <div className="atrakcje-img">
                <div style={{ flex: 1, paddingRight: '20px' }}>
                <img src="https://apartamentyportabaltica.pl/images/atrakcje/bursztyn.jpg" alt="Bursztyn" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div style={{ flex: 1, marginBottom: '30px', marginTop: '30px' }}>
                <p>
                    Muzeum Bursztynu w Kołobrzegu to wyjątkowe miejsce, które przenosi odwiedzających w fascynujący świat bursztynu, znanego także jako "złoto Bałtyku". Znajduje się ono w sercu nadmorskiego miasta. W muzeum można podziwiać imponującą kolekcję bursztynowych eksponatów, w tym biżuterię, ozdoby, a także naturalne formy bursztynu zawierające inkluzje – małe fragmenty roślin i owadów, które zachowały się w bursztynie przez miliony lat. Wystawy są uzupełnione o informacje na temat historii bursztynu, jego właściwości i metod obróbki, co czyni to miejsce nie tylko estetycznie przyjemnym, ale także edukacyjnym.</p>
                </div>
            </div>
        </div>
        <h1 style={{marginBottom: "10px"}}></h1>
        </Container>
    )
}

export default Attractions;