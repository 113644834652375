import { Container } from "react-bootstrap";

const Apartament2 = () => {
    return (
        <Container>
            <h1 className="headerTop">Apartament<p></p>"Floral Chic"</h1>
            <div className="ap-img">
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px'}}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210609-171820-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px'}}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210519-113805-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px'}}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210517-191356-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px' }}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210517-191250-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px'}}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210517-191319-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px' }}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20230108-115104.jpg' alt="A2" className="ap-src" />
                </div>
            </div>
            <h1 style={{marginBottom: "10px"}}></h1>
        </Container>
    )
}

export default Apartament2;