import { Container } from "react-bootstrap";

const Footer = () => {
    return (
        <div className="py-3 bg-body-tertiary" style={{ fontFamily: "Roboto", bottom: "0", width: "100%"}}>
            <Container>
                <div className="text-center">
                    <small>Porta Baltica Kołobrzeg &copy; 2024</small>
                </div>
            </Container>
        </div>
    )
}

export default Footer;