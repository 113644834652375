import { Container } from "react-bootstrap";

const Apartament1 = () => {
    return (
        <Container>
            <h1 className="headerTop">Apartament<p></p>"Industrial Loft"</h1>
            <div className="ap-img">
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px' }}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210519-114851-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px' }}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210519-114750-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px'}}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210519-114816-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px' }}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210519-114622-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px'}}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210517-193328-min.jpg' alt="A2" className="ap-src" />
                </div>
                <div style={{ position: 'relative', marginRight: '10px', marginBottom: '20px' }}>
                    <img src='https://apartamentyportabaltica.pl/images/galeria/IMG-20210517-192724-min.jpg' alt="A2" className="ap-src" />
                </div>
            </div>
            <h1 style={{marginBottom: "10px"}}></h1>
        </Container>
    )
}

export default Apartament1;