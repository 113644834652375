import { Routes, Route } from 'react-router-dom';

import NavBar from '../src/components/views/NavBar/NavBar';
import Footer from './components/views/Footer/Footer';
import Home from '../src/components/pages/Home/Home';
import Apartaments from '../src/components/pages/Apartaments/Apartaments'
import Attractions from './components/pages/Attractions/Attractions';
import Contact from './components/pages/Contact/Contact';
import Pricing from './components/pages/Pricing/Pricing';
import Apartament1 from './components/pages/Apartament1/Apartament1';
import Apartament2 from './components/pages/Apartament2/Apartament2';
import Gallery from './components/pages/Gallery/Gallery';

function App() {
  return (
    <main>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apartamenty" element={<Apartaments />} />
          <Route path="/atrakcje" element={<Attractions />} />
          <Route path="/galeria" element={<Gallery />} />
          <Route path="/cennik" element={<Pricing />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/apartament1" element={<Apartament1 />} />
          <Route path="/apartament2" element={<Apartament2 />} />
        </Routes>
        <Footer/>
    </main>
  );
}

export default App;
