import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

const Contact = () => {
    return (
        <Container>
            <h1 className="contactTop">Skontaktuj się z nami</h1>
            <div className="form-section d-flex justify-content-center">
                <div className="form-wrapper">
                    <form action="https://formsubmit.co/8a607738836c4f6c4e6fee3944c919e6" method="POST">

                        <input type="text" name="_honey" style={{ display: "none" }} />
                        <input type="hidden" name="_captcha" value="false" />
                        <input type="hidden" name="_template" value="table" /> 

                        <div className="form-group">
                            <input type="text" name="Imię&nbsp;i&nbsp;nazwisko" id="name" className="form-control" placeholder="Imię i nazwisko *" required />
                        </div>
                        <div className="form-group">
                            <input type="email" name="Adres&nbsp;e-mail" id="email" className="form-control" placeholder="E-mail *" required />
                        </div>
                        <div className="form-group">
                            <input type="tel" name="Numer&nbsp;telefonu" id="number" className="form-control" placeholder="Telefon *" required minLength="9" />
                        </div>
                        <div className="form-group">
                            <input type="textarea" name="Wiadomość" id="text" className="form-control" placeholder="Wiadomość *" required />
                        </div>
                        <Button variant="primary" type="submit">
                            Wyślij
                        </Button>
                    </form>           
                </div>
            </div>

            <Row xs={1} md={2} className="g-4">
                <Card className="border-0">
                    <Card.Body>
                    <Card.Text>
                    <div>
                        <h1>Kontakt</h1>
                        <div className="address">
                            <FaHome style={{ fontSize: "2em" }}/>
                            <span className="info">Generała Stanisława Maczka 13/13, 78-100 Kołobrzeg</span>
                        </div>
                        <div>
                            <MdEmail style={{ fontSize: "2em" }} />
                            <span className="info" >kontakt@apartamentyportabaltica.pl</span>
                        </div>
                        <div>
                            <FaPhoneAlt style={{ fontSize: "2em"}} />
                            <span className="info">+48 799 859 700</span>
                        </div>
                    </div>
                    </Card.Text>
                    </Card.Body>
                </Card>

                <Card className="border-0">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d18679.06901658761!2d15.604956199999998!3d54.182136799999995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47003fd3c86e33cb%3A0x1671def46b97a2ea!2sApartamenty%20Porta%20Baltica%20Ko%C5%82obrzeg!5e0!3m2!1sen!2sus!4v1717064867497!5m2!1sen!2sus" style={{width: "100%", height:"450px", border: "0", referrerpolicy:"no-referrer-when-downgrade"}}></iframe>
                </Card>
            </Row>
            <h1 style={{marginBottom: "10px"}}></h1>
        </Container>
    )
}

export default Contact;
